var eventMethod = window.addEventListener
? "addEventListener"
: "attachEvent";
var eventer = window[eventMethod];
var messageEvent = eventMethod === "attachEvent"
? "onmessage"
: "message";

eventer(messageEvent, function (e) {

  // if (e.origin !== 'https://ping-culture.com' && e.origin !== 'https://google.com') return;

  // run opening of modal if all checks out
  // PingCultureTickets.init();

});

var PingCultureTickets = (function () {

    var styles = `
    .ping-culture-tickets-modal {
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 10000; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */

      position: fixed;
      inset: 0px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      border: 0px;
      margin: 0px;
      padding: 0px;
      z-index: 2147483647;
      transition: opacity 150ms ease 0s;
      align-items: center;
      justify-content: center;
      opacity: 1;
      background: rgba(0, 0, 0, 0.8);
    }

    .ping-culture-tickets-modal-content {
      position: relative;
      margin: auto;
      // padding: 50px;
      overflow-y: scroll;
      // width: 100%;
      height: 100%;
      -webkit-animation-name: animateExpand;
      -webkit-animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
      animation-name: animateExpand;
      animation-duration: 0.3s;
      text-align: center;
    }

    .ping-culture-tickets-modal-content iframe {
      width: 100vw;
      height: 100vh;
      position: relative;
      border: none;
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
      from {top:-300px; opacity:0}
      to {top:0; opacity:1}
    }

    @keyframes animatetop {
      from {top:-300px; opacity:0}
      to {top:0; opacity:1}
    }

    /* Add Animation */
    @-webkit-keyframes animateExpand {
      from {transform:scale(0.5); opacity:0}
      to {transform:scale(1); opacity:1}
    }

    @keyframes animateExpand {
      from {transform:scale(0.5); opacity:0}
      to {transform:scale(1); opacity:1}
    }

    /* The Close Button */
    .ping-culture-tickets-close {
      color: white;
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 10;
      font-size: 28px;
      font-weight: bold;
      -webkit-animation-name: animatetop;
      opacity: 0;
      animation-fill-mode: forwards;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;
      animation-delay: 0.5s;
    }

    .ping-culture-tickets-close:hover,
    .ping-culture-tickets-close:focus {
      color: #b5b5b5;
      text-decoration: none;
      cursor: pointer;
    }

    @media (max-width: 576px) {
      // .ping-culture-tickets-modal {
      //   padding-top: 80px;
      // }

      // .ping-culture-tickets-modal-content {
      //   position: relative;
      //   background-color: #fefefe;
      //   margin: auto;
      //   padding: 0;
      //   border-radius: 12px;
      //   overflow-y: scroll;
      //   border: 1px solid #888;
      //   width: 90%;
      //   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      //   -webkit-animation-name: animatetop;
      //   -webkit-animation-duration: 0.4s;
      //   animation-name: animatetop;
      //   animation-duration: 0.4s
      // }

      // .ping-culture-tickets-modal-content iframe {
      //   width: 100%;
      //   position: relative;
      //   border: none;
      // }
  }

    .ping-ticket-button-widget {
        border: 2px solid #000000;
        border-style: solid;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        cursor: pointer;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .ping-ticket-button-widget:hover {
      cursor: pointer;
    }

    .ping-ticket-button-widget-sm {
        padding: .175rem .5rem;
        font-size: 0.75rem;
        line-height: 1.2;
        border-radius: .15rem;
    }

    .ping-ticket-button-widget-md {
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
    }

    .ping-ticket-button-widget-lg {
        padding: .5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: .3rem;
    }

    .ping-ticket-button-widget-light {
        background: white;
        border: 1px solid black;
        color: black;
    }

    .ping-ticket-button-widget-light:hover {
      color: white;
      background: black;
      border-colour: white;
    }

    .ping-ticket-button-widget-dark {
        color: white;
        background: black;
        border-colour: white;
    }

    .ping-ticket-button-widget-dark:hover {
      color: black;
      background: white;
      border-colour: black;
    }

  .ping-widget-loader,
  .ping-widget-loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .ping-widget-loader {
    margin: auto;
    font-size: 10px;
    position: absolute;
    top: 40%;
    left: 43%;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid #ffffff;
    border-left: 1.1em solid rgba(255, 255, 255, 0.2);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    background-image: url(https://bookings.ping-culture.com/assets/logo/ping-logo-white.png);
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


`;

    var jsStyles = document.createElement("style");
    if (jsStyles) {
      jsStyles.type = "text/css";
      jsStyles.rel = "stylesheet";
      if (jsStyles.styleSheet){
        // This is required for IE8 and below.
        jsStyles.styleSheet.cssText = styles;
      } else {
        jsStyles.appendChild(document.createTextNode(styles));
      }
      // jsStyles.innerText = styles;
      document.body.appendChild(jsStyles)
    }


    var elem = document.createElement('div');
    const div = `
    <div id="PingCultureTicketsModal" class="ping-culture-tickets-modal">

      <!-- Modal content -->
      <div class="ping-culture-tickets-modal-content">
      <div class="ping-culture-tickets-modal-body">
      <div class="ping-widget-loader">Loading...</div>
      <span class="ping-culture-tickets-close">&times;</span>
      </div>
      </div>

    </div>
    `

    var init = function (bookingData) {

      // remove all the previous temp booking data, so that steps are not skipped
      localStorage.removeItem('temp-ticket-data');

      elem.insertAdjacentHTML('beforeend', div);

      document.body.appendChild(elem);

      // Get the button that opens the modal and the entity ID
      var btn = document.getElementById("PingCultureTicketsModalBtn");

      var eventId;
      var eventType = 'DEFAULT';

      // if iframe doesn't pass the id of entity then grab from button
      if (bookingData && bookingData.eventId && bookingData.eventType) {
        eventId = bookingData.eventId;
        eventType = bookingData.eventType;
      } else if (btn) {
        eventId = btn.dataset.event;
        eventType = btn.dataset.type;
      }

      var modal = document.getElementById("PingCultureTicketsModal");


      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("ping-culture-tickets-close")[0];

      var modalboday = document.getElementsByClassName("ping-culture-tickets-modal-body")[0];

      // ------- TICKETS IFRAME ------
      var alreadyExistingTicketIframe = document.getElementById('PingCultureTicketsModalIframe');
      var makeIframe;
      if (alreadyExistingTicketIframe) {
        makeIframe = alreadyExistingTicketIframe;;
      } else {
        makeIframe = document.createElement("iframe");
        makeIframe.setAttribute("id", "PingCultureTicketsModalIframe");
      }

      let iframeUrl = `https://bookings.ping-culture.com/ticket?event_id=${eventId}&type=${eventType}`;

      makeIframe.setAttribute("src", iframeUrl);
      modalboday.appendChild(makeIframe);

      makeIframe.setAttribute("src", iframeUrl);

      // remove all content if previously opened iframe
      // modalboday.removeChild(modalboday.lastElementChild);

      modalboday.appendChild(makeIframe);

    if (eventId && eventType) {
      // When the user clicks the button, open the modal
      displayModal(modal);
    }

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
        modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }

    };


    return {
        init: init
    }
})();

function displayModal(modalElement) {
  modalElement.style.display = "block";
}




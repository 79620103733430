var triggerSwiper = function(){

    /* ==========================================================================
       Preloader
    ========================================================================== */
         $('#status').fadeOut();
        $('#preloader').delay(350).fadeOut('slow');
        $('body').delay(350).css({'overflow':'visible'});

                    // pixel code
    $('#goToPlayStore').on('click', function() {
        fbq('track', 'PlayStore', true);
      });
      $('#goToAppStore').on('click',function() {
        fbq('track', 'AppStore', true);
      });


    $('#contact-form input, #contact-form textarea').on('input',function(e){
        let currentHref = $('#contact-submit').attr('href');
        if (currentHref != '') {
            currentHref =
                'mailto:info@ping-culture.com?subject=App Website Query&body=' +
                encodeURIComponent(
                    'Name: ' + '\r' + $('#contact-form input[name="name"]').val() + '\r \r' +
                    'Email: ' + '\r' + $('#contact-form input[name="email"]').val() + '\r' + '\r' +
                    'Message: ' + '\r' + $('#contact-form textarea[name="message"]').val()
                )
            ;
        }

        // check for spam
        if ($(this).val().includes('href')) {
            currentHref = '';
        }

        $('#contact-submit').attr('href', currentHref);
    });

};

initPhoneScroller = function() {
  var isUserPage = $('body').hasClass('user');
  var isMobile = $(window).width() < 768;
  // show/hide correct business content
  // var businessType = window.location.href.includes('?') ?
  // window.location.href.substring(window.location.href.indexOf('?') + 1) :
  // 'venue';
  // changeBusinessContent(businessType);

  // run emergence script
  // emergence.init({ reset: true, elemCushion: 0 });

  // Links in nav
  $('[data-href]').click(function() {
    $('html, body').animate(
      {
        scrollTop:
          $('section[data-section="' + $(this).data('href') + '"').offset()
            .top - 55
      },
      1000
    );
    if (isMobile) {
      $(this).toggleClass('navigation__dropdown--active');
      $('.navigation__menu').slideToggle();
    }
  });

  $('.scroller').click(function() {
    $(this)
      .parents('section')
      .next('section');
  });

  $('.section__next').click(function() {
    $('html, body').animate(
      {
        scrollTop:
          $(this)
            .parents('.section')
            .next('.section')
            .offset().top - 55
      },
      500
    );
  });

  $('.phone-next').click(function() {
    $('html, body').animate(
      {
        scrollTop:
          $(this)
            .parents('.phone-section')
            .next('.phone-section')
            .offset().top - 55
      },
      500
    );
  });

  if (!isMobile) {

    $(window).scroll(function() {
      var position = window.pageYOffset;
      checkPhoneScroller();
    });
  }

  //Phone scroller - switch out images
  function checkPhoneScroller() {
    var scrollerSection = $('.section__scroller');
    $('.section__phone--active .phone-section').each(function() {
      var target = $(this);
      var targetTop = target.offset().top;
      var targetBottom = target.offset().top + target.outerHeight();
      var order = target.data('order');
      var isBlack = order % 2 == 0 ? true : false;
      // var otherTargets = $('.section__phone--active .phone-section').not(this);
      if (window.innerWidth > 768 && window.pageYOffset > (targetTop - window.innerHeight) && (window.pageYOffset + window.innerHeight) < targetBottom) {
        var newPhone = $('.phone__img[data-order="' + order + '"]');
        var otherPhones = $('.phone__img').not('.phone__img[data-order="' + order + '"]');
        if (!newPhone.hasClass('phone__img--visible')){
          otherPhones.removeClass('phone__img--visible');
          newPhone.addClass('phone__img--visible');
        }
        // if (!target.hasClass('phone-section--visible')) {
        //   target.addClass('phone-section--visible');
        //   otherTargets.removeClass('phone-section--visible');
        // }
        if (isBlack) {
          if (!scrollerSection.hasClass('bg-black')) {
            scrollerSection.addClass('bg-black');
          }
        } else {
          scrollerSection.removeClass('bg-black');
        }
      }
    });
  }
};
